import * as React from 'react'
import Layout from '../components/layout'

const RatesPage = () => {
  return (
    <Layout pageTitle="Rates"
            snipit="">
      <table className="rates table text-center">
        <thead>
          <tr>
            <th>Duration</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>30 minutes</td>
            <td>$75</td>
          </tr>
          <tr>
            <td>45 minutes</td>
            <td>$105</td>
          </tr>
          <tr>
            <td>60 minutes</td>
            <td>$125</td>
          </tr>
        </tbody>
      </table>

    </Layout>
  )
}

export default RatesPage
